import { httpopen } from './configOpen'
import { http } from './config'

import authHeader from './auth-header';

export default {

    totais_dash_prom: () => {
        return http.get('totais_dash_prom', {headers: authHeader() })
    },
    
    ranking_saldo: () => {
        return http.get('ranking_saldo', {headers: authHeader() })
    },

    historico_premiacao_situacao: (situacao) => {
        return http.get('historico_premiacao_situacao/'+ situacao, {headers: authHeader() })
    },


    historico_atividade:() => {
        return http.get('historico_atividade', {headers: authHeader() })
    },

    lojastatus:() => {
        return http.get('lojastatus', {headers: authHeader() })
    },

    lojasupdate:() => {
        return http.get('lojasupdate', {headers: authHeader() })
    },
    

    

}

<template>
<v-app>


  <v-container
    id="dashboard"
    fluid
    tag="section">
    
    <v-row>

      <v-col cols="12" sm="8" lg="6">
          <v-card-title class="text-h3">
            <v-icon style="color:#FFD700;font-size:48px">mdi-trophy</v-icon>  &nbsp; Ranking
          </v-card-title>
        <apexcharts style="margin-left:50px" width="580" height="350" type="bar" :options="chartOptions" :series="series"></apexcharts>
      </v-col>  


      <v-col
          cols="12"
          sm="6"
          lg="2">
        
      </v-col> 
  
      <v-col
        cols="12"
        sm="6"
        lg="4">
          <v-sheet
          width="100%"
          height="100%"
          class="pa-12"
        >
          <v-switch @change="updateLoja()"
            v-model="switch1"
            
            :label="`Status da Loja`"
          ></v-switch>
        </v-sheet>

      </v-col>
    </v-row>

    <v-row>
        <v-col  cols="12" sm="6" lg="4">
          <v-card
            color="#4da2b2"
            dark
          >
          <v-card-title class="text-h5">
                   <v-icon>mdi-gift</v-icon>  &nbsp; Prêmios Utilizados - Histórico
            </v-card-title>
            <br/>
           <v-card-subtitle> <h1>{{this.total_premiado}}</h1></v-card-subtitle>

          </v-card>
        </v-col>

        <v-col cols="12" sm="6" lg="4">
          <v-card
            color="#4da2b2"
            dark
          >
          <v-card-title class="text-h5">
                           <v-icon>mdi-trophy</v-icon>  &nbsp; Pontuação Acumulada - Histórico
            </v-card-title>
            <br/>
           <v-card-subtitle> <h1>{{this.total_acumulado}}</h1></v-card-subtitle>

          </v-card>
        </v-col>

         <v-col
          cols="12"
            sm="6"
            lg="4">

            <v-card
                color="#fb8c00"
                dark>
                <v-card-title class="text-h5">
                      <v-icon>mdi-trophy</v-icon>  &nbsp; Saldo de Pontos
                </v-card-title>
                <br/>
              <v-card-subtitle > <h1>{{this.saldo}}</h1></v-card-subtitle>
            </v-card>
          </v-col>

           </v-row>

    <v-row>



      <v-col
       cols="12"
        sm="6"
        lg="3"
      >
       
        <base-material-stats-card
          color="red"
          icon="mdi-map-marker-radius"
          title=" Endereço"
          sub-icon-color="red"
           :value='this.total_endereco' sub-text="Total de Pontos: "  :subTextValor="this.sum_endereco"
        />
       
      </v-col>

       <v-col
       cols="12"
        sm="6"
        lg="3"
      >
       
        <base-material-stats-card
          color="black"
          icon="mdi-at"
          title="Email"
           :value='this.total_email'
          sub-icon-color="red"
          sub-text="Total de Pontos: " :subTextValor="this.sum_email"
        />
      </v-col>


             <v-col
       cols="12"
        sm="6"
        lg="3"
      >
       
        <base-material-stats-card
          color="blue"
          icon="mdi-phone"
          title="Telefone"
           :value='this.total_telefone' :subTextValor="this.sum_telefone"
          sub-icon-color="red"
          sub-text="Total de Pontos"
        />
       
      </v-col>


       <v-col
       cols="12"
        sm="6"
        lg="3">
       
        <base-material-stats-card
          color="purple"
          icon="mdi-shopping"
          title="Vendas"
           :value='this.total_vendas' :subTextValor="this.sum_vendas"
          sub-icon-color="red"
          sub-text="Total de Pontos"
        />
       
      </v-col>
       <v-col
       cols="12"
        sm="6"
        lg="3"
      >
       
        <base-material-stats-card
          color="green"
          icon="mdi-hospital-box"
          title="Previda Mais"
           :value='this.total_previdamais' :subTextValor="this.sum_previdamais"
          sub-icon-color="red"
          sub-text="Total de Pontos"
        />
       
      </v-col>

          <v-col
       cols="12"
        sm="6"
        lg="3"
      >
       
        <base-material-stats-card
          color="orange"
          icon="mdi-arrow-top-right-bold-box"
          title="Indicação"
           :value='this.total_indicacao' :subTextValor="this.sum_indicacao"
          sub-icon-color="orange"
          sub-text="Total de Pontos"
        />
       
      </v-col>

      <v-col
       cols="12"
        sm="6"
        lg="3"
      >
       
        <base-material-stats-card
          color="brown"
          icon="mdi-arrow-top-right-bold-box"
          title="Fatura Digital"
           :value='this.total_carne' :subTextValor="this.sum_carne"
          susb-icon-color="orange"
          sub-text="Total de Pontos"
        />
       
      </v-col>
             
    </v-row>


<base-material-card
      icon="mdi-gift"
      title="Histórico de Premiados"
      color="info"
      class="px-5 py-3"
    >

      <v-data-table
      :headers="headers_premiacoes"
      :items="premiacoes"
      :items-per-page="5"
      class="elevation-1">
      
      <template v-slot:item="row">
          <tr>
            <td style="width: 20%;font-size:14px;">{{row.item.nome}}</td>
            <td style="width: 12%;font-size:14px;">{{row.item.data_insert}}</td>
            <td style="width: 20%;font-size:14px;">{{row.item.evento}}</td>
            <td style="width: 6%;font-size:14px;">{{row.item.pontos}}</td>

            <td style="width: 10%;font-size:14px;">
                    <v-chip
                      :color="getBadge(row.item.situacao)"
                      dark
                    >
                      {{row.item.situacao}}               
                    </v-chip>
              
            </td>
            
            <td style="width: 10%;font-size:14px;">{{row.item.data_situacao}}</td>
            <td style="width: 12%;font-size:14px;">{{row.item.user_situacao}}</td>

        </tr>
       </template> 
    
    </v-data-table>


 <v-card-title class="text-h1">
    <download-excel :data="premiacoes" :fields="json_fields">
       <v-icon style="color:green;font-size:32px">mdi-file-excel</v-icon>
    </download-excel>
  </v-card-title>


    </base-material-card>
 
  
  <base-material-card
      icon="mdi-star"
      title="Histórico de Atividades"
      color="info"
      class="px-5 py-3"
    >

      <v-data-table
      :headers="headers_atividades"
      :items="atividades"
      :items-per-page="5"
      class="elevation-1">
      
      <template v-slot:item="row">
          <tr>
            <td style="width: 25%;font-size:14px;">{{row.item.nome}}</td>
            <td style="width: 15%;font-size:14px;">{{row.item.data_insert}}</td>
            <td style="width: 20%;font-size:14px;">{{row.item.evento}}</td>
            <td style="width: 10%;font-size:14px;">{{row.item.pontos}}</td>
            <td style="width: 10%;font-size:14px;">{{row.item.bloqueado}}</td>

        </tr>
       </template> 
    
    </v-data-table>


 <v-card-title class="text-h1">
    <download-excel :data="atividades" :fields="json_fields_a">
       <v-icon style="color:green;font-size:32px">mdi-file-excel</v-icon>
    </download-excel>
  </v-card-title>


    </base-material-card>

  </v-container>

</v-app> 
</template>

<script>
  import Promotor from '../../../services/promotor'
  import VueApexCharts from 'vue-apexcharts'



  export default {
  components: {
    apexcharts: VueApexCharts,
  },

    mounted() {
        this.totais_dash_prom();
        this.ranking();  
        this.historico_atividade();
        this.historicoPremiados();
        this.statusLoja();
    },

    name: 'DashboardDashboard',
 
    data () {
      return {
        switch1: false,
        saldo: '0',
        token: '',
        promotores_pontos: [],
        promotores_nomes: [],
        key: '',
        total_acumulado: 0,
        total_premiado: 0,
        total_vendas: 0,
        total_endereco: 0,
        total_email: 0,
        total_telefone: 0,
        total_indicacao: 0,
        total_contato_end: 0,
        total_contato_ema: 0,
        total_previdamais: 0,
        total_carne: 0,
        sum_vendas: 0,
        sum_endereco: 0,
        sum_email: 0,
        sum_telefone: 0,
        sum_indicacao: 0,
        sum_contato_end: 0,
        sum_contato_ema: 0,
        sum_previdamais: 0,
        sum_carne: 0,
        eventos: [],
        premiacoes:[],
        atividades: [],
        json_fields: {
          "Jogador": "nome",
          "Prêmio": "evento",
          "Pontos do Prêmio": "pontos",
          "Data Solicitação": "data_insert",
          "Data Resposta": "data_situacao",
          "Usuário Resposta": "user_situacao",

          },
           json_fields_a: {
          "Jogador": "nome",
          "Data Atividade": "data_insert",
          "Atividade": "evento",
          "Pontos": "pontos",
          "Bloqueado": "bloqueado",

          },
         headers_premiacoes: [
          { text: 'Jogador', value: 'nome' },
          { text: 'Data Solicitação', value: 'data_insert' },
          { text: 'Evento', value: 'evento' },
          { text: 'Pontos', value: 'pontos' },
          { text: 'Situação', value: 'situacao' },
          { text: 'Data Situação', value: 'data_situacao' },
          { text: 'Usuário', value: 'user_situacao' },

        ],
         headers_atividades: [
          { text: 'Jogador', value: 'nome' },
          { text: 'Data Atividade', value: 'data_insert' },
          { text: 'Atividade', value: 'evento' },
          { text: 'Pontos', value: 'pontos' },
          { text: 'Bloqueado', value: 'bloqueado' },

        ],
     
         series: [{
            data: [],
          }],
          chartOptions: {
            
          },

      } 
    },

    methods: {

      historicoPremiados(){
        Promotor.historico_premiacao_situacao('APROVADO').then(response => {
                if(response.status = 200){
                  this.premiacoes = response.data;
                }
              }).catch(e => {
              }).finally(() => {
            });
      },

      statusLoja(){
        Promotor.lojastatus().then(response => {
                if(response.status = 200){
                  this.switch1 = response.data;
                }
              }).catch(e => {
              }).finally(() => {
            });
      },

      updateLoja(){
        Promotor.lojasupdate().then(response => {
                if(response.status = 200){
                  this.switch1 = response.data;
                }
              }).catch(e => {
              }).finally(() => {
            });
      },

      historico_atividade(){
            Promotor.historico_atividade().then(response => {
                if(response.status = 200){
                  this.atividades = response.data;
                }
              }).catch(e => {
              }).finally(() => {
            });

      },

      ranking(){
           Promotor.ranking_saldo().then(response => {
               if(response.status = 200){
                  this.promotores_nome = response.data.lista_nome;
                  this.promotores_pontos = response.data.lista_pontos;

                    this.series =  [{
                      data: this.promotores_pontos
                    }]

                    this.chartOptions = {
                                  chart: {
                                    type: 'bar',
                                    height: 350
                          },
                          plotOptions: {
                            bar: {
                              borderRadius: 4,
                              horizontal: true,
                            }
                          },
                         
                          dataLabels: {
                            enabled: true,
                            textAnchor: 'start',
                            style: {
                              colors: ['#fff']
                            },
                           
                          },
                           colors: ['#33b2df', '#546E7A', '#d4526e', '#13d8aa', '#A5978B', '#2b908f', '#f9a3a4', '#90ee7e','#f48024', '#69d2e7'
                          ],
                          xaxis: {
                            categories: this.promotores_nome,
                            trim: true,
                            labels: {
                              show: false,
                            },
                            lines: {
                              show: false  //or just here to disable only x axis grids
                            }
                          }
                    }






                }
              }).catch(e => {

              }).finally(() => {

            });
      },

      totais_dash_prom() {
          Promotor.totais_dash_prom().then(response => {
                if(response.status = 200){
                   this.saldo = response.data.saldo;
                   this.total_endereco = response.data.total_endereco;
                   this.sum_endereco = response.data.sum_endereco;

                   this.total_telefone = response.data.total_telefone;
                   this.sum_telefone = response.data.sum_telefone;
                   
                   this.total_email = response.data.total_email;
                   this.sum_email = response.data.sum_email;

                   this.total_vendas = response.data.total_venda;
                   this.sum_vendas = response.data.sum_venda;

                   this.total_previdamais = response.data.total_previda;
                   this.sum_previdamais = response.data.sum_previdamais;

                   this.total_acumulado = response.data.total_acumulado;
                   this.sum_acumulado = response.data.sum_acumulado;

                   this.total_premiado = response.data.total_premiado;
                   this.sum_premiado = response.data.sum_premiado;

                   this.total_indicacao = response.data.total_indicacao;
                   this.sum_indicacao = response.data.sum_indicacao;

                   this.total_carne = response.data.total_carne;
                   this.sum_carne = response.data.sum_carne;
                   
                   this.token = response.data.token;
                   this.key = response.data.key;

               
                }
              }).catch(e => {
                 if(e.response.status === 401){
                  this.$store.dispatch('auth/logout');
                  this.$router.push('/login');
                  }
              }).finally(() => {
            });

        },

        getLoja(status) {
          switch (status) {
            case true: return 'Ativo'
            case false: return 'Inativo'
            default: ''
         }
        },

        getBadge (status) {
          switch (status) {
            case 'APROVADO': return 'green lighten-1'
            case 'RECUSADO' : return 'red lighten-1'
            default: 'success'
          }
        },
    },
  }
</script>
